.iotm2m{
    width: 100%;
    height: auto;
}
.iotm2m section{
    width: 100%;
    height: auto;
    background-color: #02000e;
    background-image: url('../../images/iotm2m/bg5.svg');
    background-size: cover;
    color: #fff;
}
.iotm2m>section>header{
    text-align: center;
    padding-top: 200px;
}
.iotm2m>section>header>span {
    font-size: 5rem;
    font-weight:bold;
    font-family: OpenSans;
}
.iotm2m>section>header>p{
    font-size:2.1rem;
    margin-top: 1.5rem;
    font-family: OpenSans;
}
.iot-line{
    display: flex;
    justify-content: center;
    margin-top:160px;
}
.iot-line>ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    
}
.iot-line>ul>li{
    position: relative;
    border-top:1px dashed #aaa;
    width:20vw;
}
.iot-line>ul>li:last-child {
    border: none;
    width:0;
}
.iot-line>ul>li>div {
    position:absolute;
    border:3px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    background-color:#fff;
    width:12px;
    height:12px;
    top:-9px
}
.iot-line>ul>li>p {
    position:absolute;
    left:-85px;
    width:190px;
    text-align:center;
    margin-top:55px;
    font-size: 2.1em;
}
#iot-contents{
    margin: 180px 0 0 210px;
    padding-bottom: 30px;
}
#iot-contents .swiper-btn-container{
    display:none;
}
#iot-contents .scroll-home-second-xscroll::-webkit-scrollbar {
    display: block;
    height: 5px;
    margin-bottom:60px ;
    /* Chrome, Safari, Opera*/
}

#iot-contents:hover .scroll-home-second-xscroll::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
}
.iot-box{
    height: auto;
    width: 432px;
    border-radius: 10px;
    background-color: rgba(26, 29, 51, 0.9);
    word-break: keep-all;
    font-size: 0.88rem;
    margin-right: 25px;
    margin-bottom: 30px;
}
.iot-box>div{
    width: 22vw;
}
.iot-box>p {
    width: 320px;
    min-width: 220px;
    margin: 3rem auto 4rem;
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
}

.iot-box>span {
    font-size: 1.6rem;
    display: block;
    width: 320px;
    height:255px;
    min-width: 220px;
    margin: 40px 50px 60px;
    padding-right: 2.2rem;
    opacity: 0.7;
    font-family: SpoqaHanSansNeo;
    text-align: left;
    line-height: 1.63;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
.iot-box>span::-webkit-scrollbar {
    width: 5px;
    opacity: 0;
    
    /* Chrome, Safari, Opera*/
}
.iot-box>span::-webkit-scrollbar-thumb{
    background-color: rgba(255,255,255,0.3);
}

.iot-box>img{
    margin-top: 75px;
    margin-left: 50px;
}


@media (max-width:1024px) {
    .iotm2m {
        width: 100%;
        height: auto;
    }

    .iotm2m section {
        width: 100%;
        height: auto !important;
        background-color: #02000e;
        background-image: url('../../images/iotm2m/bg5.svg');
        background-size: cover;
        color: #fff;
    }

    .iotm2m>section>header {
        text-align: center;
        padding-top: 18rem;
    }

    .iot-line {
        display: flex;
        justify-content: center;
        margin-top: 15.5rem;
        margin-left: -11px;
    }

    .iot-line>ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 0;

    }

    .iot-line>ul>li {
        position: relative;
        border-top: 1px dashed #aaa;
        width: 20vw;
    }

    .iot-line>ul>li:last-child {
        border: none;
        width: 0;
    }

    .iot-line>ul>li>p {
        position: absolute;
        left: -9vw;
        width: 20vw;
        text-align: center;
        margin-top: 50px;
        font-size: 2.4vw;
    }

    #iot-contents {
        margin: 180px 0 0 60px;
        padding-bottom: 30px;
    }

    #iot-contents .swiper-btn-container {
        display: none;
    }

    #iot-contents .scroll-home-second-xscroll::-webkit-scrollbar {
        display: block;
        height: 5px;
        margin-bottom: 60px;
        /* Chrome, Safari, Opera*/
    }

    #iot-contents:hover .scroll-home-second-xscroll::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.3);
    }
    .iot-box {
        margin-bottom: 18rem;
    }
    .iot-box>div {
        width: 22vw;
    }

    .iot-box>p {
        width: 320px;
        margin: 30px auto 20px;
        text-align: left;
        font-weight: bold;
    }

    .iot-box>span {
        display: block;
        width: 320px;
        height: 255px;
        margin: 40px 50px 60px;
        opacity: 0.7;
        font-family: SpoqaHanSansNeo;
        text-align: left;
        overflow-x: hidden;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .iot-box>span::-webkit-scrollbar {
        width: 5px;
        opacity: 0;
        /* Chrome, Safari, Opera*/
    }

    .iot-box>span::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.3);

    }

    .iot-box>img {
        margin-top: 75px;
        margin-left: 50px;
    }
}
@media (max-width:600px) {
    .iotm2m {
        width: 100%;
        height: auto;
    }

    .iotm2m section {
        width: 100%;
        height: auto !important;
        background-color: #02000e;
        background-image: url('../../images/iotm2m/bg5.svg');
        background-size: cover;
        color: #fff;
    }

    .iotm2m>section>header {
        text-align: center;
        padding-top: 200px;
    }

    .iotm2m>section>header>span {
        font-size: 3.5rem;
    }

    .iotm2m>section>header>p {
        font-size: 1.8em;
        margin: 0;
    }

    .iot-line {
        display: none;
        justify-content: center;
        margin-top: 160px;
    }

    .iot-line>ul {
        display: none;
        flex-direction: row;
        list-style: none;
        padding: 0;

    }

    .iot-line>ul>li {
        position: relative;
        border-top: 1px dashed #aaa;
        width: 20vw;
    }

    .iot-line>ul>li:last-child {
        border: none;
        width: 0;
    }

    .iot-line>ul>li>div {
        position: absolute;
        border: 4px solid rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        background-color: #fff;
        width: 14px;
        height: 14px;
        top: -11px
    }

    .iot-line>ul>li>p {
        position: absolute;
        left: -85px;
        width: 190px;
        text-align: center;
        margin-top: 55px;
        font-size: 1.16rem;
    }

    #iot-contents {
        position: relative;
        margin: 100px 7.5vw 0;
        padding-bottom: 9rem;
    }

    #iot-contents .swiper-btn-container {
        position: absolute;
        bottom: 10% !important;
        display: flex;
        
    }

    #iot-contents .scroll-home-second-xscroll::-webkit-scrollbar {
        display: none;
        height: 5px;
        margin-bottom: 60px;
        /* Chrome, Safari, Opera*/
    }

    #iot-contents:hover .scroll-home-second-xscroll::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.3);
    }

    .iot-box {
        scroll-snap-align: start;
        height: auto;
        width: 85vw;
        border-radius: 10px;
        word-break: keep-all;
        margin-right: 25px;
        margin-bottom: 6rem;
    }

    .iot-box>div {
        width: 22vw;
    }

    .iot-box>p {
        width: 50vw;
        margin: 30px 10vw 20px;
        text-align: left;
        font-size: 3rem;
    }

    .iot-box>span {
        font-size: 1.6rem;
        line-height: 1.63;
        display: block;
        width: 65vw;
        height: 255px;
        margin: 40px 10vw 60px;
        overflow-x: hidden;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .iot-box>span::-webkit-scrollbar {
        width: 5px;
        opacity: 0;
        /* Chrome, Safari, Opera*/
    }

    .iot-box>span::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.3);

    }

    .iot-box>img {
        margin-top: 75px;
        margin-left: 50px;
    }
}