.swiper-xscroll {
    display: flex;
    width: 100%;
    flex-direction: row;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.swiper-xscroll::-webkit-scrollbar {
    display: none;
}
.swiper-btns{
    position: relative;
}
.swiper-btns .move-btn{
    position: none;
    display:flex;
    background-color: rgba(0,0,0,0);
    border: none;
}
.swiper-btns .move-btn img{
    width: 2.8rem;
}
.opacity-swiper{
    position: relative;
}
.swiper-xscroll .slide{
    position: absolute;
    opacity: 0;
    transition: transform 900ms ease, scale 900ms ease;
    visibility: hidden;
}
.swiper-xscroll .slide.active  {
    transform: translate3d(0%, 0, 0px);
    opacity: 1;
    visibility: visible;
}
.swiper-xscroll .slide.prev1 {
    transform: translate3d(-120%, -10%, -600px);
    opacity: 0.5;
    scale: 0.61;
    visibility: visible;
}
.swiper-xscroll .slide.prev2 {
    transform: translate3d(-220%, -10%, -600px);
    opacity: 0.5;
    scale: 0.61;
    visibility: visible;
}
.swiper-xscroll .slide.prev3 {
    transform: translate3d(-320%, -10%, -600px);
    opacity: 0;
    scale: 0.61;
    visibility: visible;
}
.swiper-xscroll .slide.next1 {
    transform: translate3d(120%, -10%, -600px);
    opacity: 0.5;
    scale: 0.61;
    visibility: visible;
}
.swiper-xscroll .slide.next2 {
    transform: translate3d(220%, -10%, -600px);
    opacity: 0.5;
    scale: 0.61;
    visibility: visible;
}
.swiper-xscroll .slide.next3 {
    transform: translate3d(320%, -10%, -600px);
    opacity: 0;
    scale: 0.61;
    visibility: visible;
}

.swiper-pagination{
    overflow-x: scroll;
}
.swiper-pagination::before{
    content: " ";
    margin-left: 2rem;
}
.swiper-pagination::after{
    content: " ";
    margin-right: 2rem;
}
.swiper-pagination::-webkit-scrollbar {
    display: none;
}
@media (max-width: 1100px){
    .swiper-pagination{
        width: 100vw;
    }
    .swiper-btns {
        width: 1020px;
    }
}
@media (max-width: 700px){
    .swiper-btns {
        width: 970px;
    }
}