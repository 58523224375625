@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/OpenSans/OpenSans-Bold.ttf');
    src: url('../../fonts/OpenSans/OpenSans-Light.ttf');
    src: url('../../fonts/OpenSans/OpenSans-Medium.ttf');
    src: url('../../fonts/OpenSans/OpenSans-Regular.ttf');
}

    html{
        font-family: 'OpenSans';
        font-size: 62.5%;
    }
    .wrap-container-scrollpage {
        scroll-snap-type: none;
    }
    .wrap-container-serviceai section {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-direction: column;
        background-color: #000;
        padding-bottom: 10rem;
    }

    .serviceai-page-first {
        position: relative;
        background-size: cover;
        color: #fff;
        text-align: center;
    }
    .serviceai-page-first-contents{
        display: flex;
        margin-top: 25vh;
        flex-direction: column;
        align-items: center;
        z-index: 1;
    }
    .serviceai-page-first-text {
        width:730px;
    }
    .serviceai-page-first-text>p:nth-child(1){
        font-family: 'OpenSans';
        font-weight: bold;
        font-size: 5rem;
        line-height: 1.4;
    }
    .serviceai-page-first-text>span:nth-child(2){
        font-family: 'OpenSans';
        font-size: 2.1rem;
    }
    .serviceai-page-first-text>p:nth-child(3) {
        font-family: 'SpoqaHanSansNeo';
        font-size: 1.8rem;
        font-weight: bold;
        word-break: keep-all;
        margin: 10rem 0 6rem;
        line-height: 1.67;
    }
    .serviceai-page-first-text>span:nth-child(4) {
        font-family: 'SpoqaHanSansNeo';
        font-size: 1.8rem;
        opacity: 0.7;
        word-break: keep-all;
        line-height: 1.67;
    }

    .wrap-container-serviceai section.serviceai-page-first {
        padding-bottom: 70rem;
    }

    #serviceai {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 144rem;
    }
    #serviceai .swiper-slide {
        width: 97%;
    }
    #serviceai .move-btn {
        display: block;
        background-color: transparent;
        border: none;
        z-index: 2;
        bottom: 35vh;
        cursor: pointer;
    }
    #serviceai .move-btn.prev-btn {margin-right: 1.5rem;}
    #serviceai .move-btn>img {
        width: 28px;
        height: 56px;
    }
    #serviceai>.swiper-slide>.swiper-btn-container{
        visibility: hidden;
    }
    #serviceai .serviceai-box{
        min-width: 34rem;
        height: 69rem;
        margin-top: 18rem;
        margin-right: 3.8rem;
        border-radius: 10px;
        padding: 5rem 4.6rem 0rem 4.6rem;
        background-color: #18191f;
        word-break: keep-all;
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
    }

    /* #serviceai .serviceai-box:first-child {margin-left: 3.6rem;} */
    .serviceai-box>img {
        display: inline-block;
        width: 100%;
        -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0.2)));
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2));
    }
    .serviceai-box .dyvity-logo {
        width: initial;
        display: inline-block;
        text-align: left;
        margin-top: 4.2rem;
    }

    .serviceai-box .wastem-logo {
        width: initial;
        display: inline-block;
        text-align: left;
        margin: 0 0 1.5rem;
    }

    #serviceai > div > div.scroll-home-second-xscroll > div:nth-child(4) > img.dyvity-logo {
        margin-top: 1rem;
    }
    .serviceai-box>p {
        text-align: left;
        font-size: 2.8rem;
        font-weight: bold;
        margin: 1.5rem 0 3rem;
        letter-spacing: 0.07rem;
    }
    .serviceai-box>span {
        display: block;
        margin-bottom: 3rem;
        opacity: 0.7;
        font-family: SpoqaHanSansNeo;
        text-align: left;
        font-size: 1.6rem;
        line-height: 1.63;
    }
    .serviceai-box>span.title-sub {
        color: #aeafc5;
        opacity: 0.7;
    }
    .serviceai-page-second{
        min-height: 100%;
        color: #fff;
        font-family: OpenSans;
        background: linear-gradient(173deg, #000 50%, #17073c 70%, #081482 90%);
    }
    .serviceai-page-second p{
        margin-top:190px;
        margin-bottom: 10px;
        font-size: 2.58rem;
        font-weight: bold;
    }
    .serviceai-page-second span {
        font-size: 1.16rem;
    }
    .serviceai-page-second>img{
        margin-top:68px;
        width: 65vw;
        max-width:1280px;
    }
    .serviceai-page-second>a{
        margin-top:100px;
        width:210px;
        height: 44px;
        border-radius: 22px;
        background-color: #fff;
        text-decoration: none;
        font-size: 0.77rem;
        font-weight: 600;
        text-align:center;
        line-height: 44px;
        color: #000;
        margin-bottom: 140px;
    }

/* opacityswipe */

.swiper-xscroll {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.swiper-slide.opacity-swiper {
    position: relative;
}

.swiper-xscroll::-webkit-scrollbar {
    display: none;
}
.serviceai-page-second .move-btn{
    position: none;
    display:flex;
    background-color: rgba(0,0,0,0);
    border: none;
    cursor: pointer;
}
.serviceai-page-second .move-btn img{
    width: 2.8rem;
}
.serviceai-page-second .prev-btn {
    position: absolute;
    left: 27rem;
    top: 23rem;
}
.serviceai-page-second .next-btn {
    position: absolute;
    right: 27rem;
    top: 23rem;
}
.opacity-swiper{
    position: relative;
}
.swiper-xscroll .slide{
    position: absolute;
    opacity: 0;
    transition: transform 900ms ease, scale 900ms ease;
    visibility: hidden;
}
.swiper-xscroll .slide.active {
    transform: translate3d(0%, 0, 0px);
    opacity: 1;
    visibility: visible;
}
.swiper-xscroll .slide.active .logo-description {
    display: block;
    padding: 0rem 5rem;
}
.swiper-xscroll .slide.prev1 {
    transform: translate3d(-120%, -10%, -600px);
    opacity: 0.5;
    scale: 0.61;
    visibility: visible;
}
.swiper-xscroll .slide.prev2 {
    transform: translate3d(-220%, -10%, -600px);
    opacity: 0.5;
    scale: 0.61;
    visibility: visible;
}
.swiper-xscroll .slide.prev3 {
    transform: translate3d(-320%, -10%, -600px);
    opacity: 0;
    scale: 0.61;
    visibility: visible;
}
.swiper-xscroll .slide.next1 {
    transform: translate3d(120%, -10%, -600px);
    opacity: 0.5;
    scale: 0.61;
    visibility: visible;
}
.swiper-xscroll .slide.next2 {
    transform: translate3d(220%, -10%, -600px);
    opacity: 0.5;
    scale: 0.61;
    visibility: visible;
}
.swiper-xscroll .slide.next3 {
    transform: translate3d(320%, -10%, -600px);
    opacity: 0;
    scale: 0.61;
    visibility: visible;
}

.serviceai-page-second {
    height: 100vh;
    padding-top: 25rem;
}
.serviceai-page-second .serviceai-page-second-text {
    text-align: center;
}
.serviceai-page-second .serviceai-page-second-text span {
    margin-top: 2.5rem;
    margin-bottom: 6rem;
    font-size: 2.1rem;
    display: block;
    font-family: OpenSans;
}
.serviceai-page-second .slide {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}
.serviceai-page-second .slide .logo-description {
    margin-top: 6.5rem;
    text-align: center;
    display: none;
}
.serviceai-page-second .slide .logo-description img {
    display: inline-block;
}
.serviceai-page-second .slide .logo-description span {
    margin-top: 2.5rem;
    display: block;
    font-size: 1.8rem;
    opacity: 0.7;
    text-align: center;
    width: 35rem;
    line-height: 1.6;
    word-break: keep-all;
}
.serviceai-page-second .swiper-pagination {
    margin: 0 auto 13rem;
    margin-bottom: 13rem;
    display: flex;
}
.serviceai-page-second .swiper-pagination button {
    outline: none;
    box-shadow: none;
    border: 1px solid #fff;
    background: none;
    color: #fff;
    font-size: 1.8rem;
    font-weight: bold;
    font-family: OpenSans;
    padding: 1.4rem 3.8rem;
    border-radius: 25px;
    margin-right: 2rem;
    opacity: 0.3;
    cursor: pointer;
}
.serviceai-page-second .swiper-pagination button.active {
    opacity: 1;
}

.serviceai-page-second .swiper-pagination button:last-child {
    margin-right: 0;
}
.serviceai-page-second .swiper-btns .prev-btn {}
.serviceai-page-second .swiper-btns .next-btn {}


#dyvity .swiper-pagination button.pag-active{
    opacity: 1;
}

@media (max-width:1530px) {
    #serviceai {
        justify-content: center;
    }
    #serviceai .serviceai-box {
        padding: 4rem 3.6rem 0rem 3.6rem;
    }
    #serviceai .swiper-slide {
        width: 91%;
    }

    #serviceai .move-btn.prev-btn {
        margin-right: 1rem;
    }
    #serviceai .move-btn.next-btn {
        margin-left: 1rem;
    }
}
@media (max-width:1438px) {
    #serviceai .serviceai-box {
        min-width: 30rem;
    }
    #serviceai .swiper-slide {
        width: 83%;
    }
}

@media (max-width:1386px) {
    .serviceai-box>p {
        font-size: 2.2rem;
    }
    #serviceai .serviceai-box {
        height: 65rem;
    }
    
}
@media (max-width:1315px) {
    #serviceai .serviceai-box {
        height: 60rem;
    }
    #serviceai .serviceai-box {
        min-width: 27rem;
    }
    .serviceai-box>span {
        font-size: 1.4rem;
    }
    #serviceai .swiper-slide {
        width: 76%;
    }
}

@media (max-width:1190px) {
    #serviceai .serviceai-box {
        height: 57rem;
        min-width: 25rem;
        margin-right: 2.8rem;
    }
    .serviceai-box>p {
        font-size: 2rem;
    }
    #serviceai .swiper-slide {
        width: 71%;
    }

}
@media (min-width:1025px) and (max-width:1189px){
    #serviceai .serviceai-box {
        margin-right: 2rem;
        padding: 4rem 2.5rem 0rem 2.8rem;
    }
    #serviceai .swiper-slide {
        width: 66%;
    }
}
@media (max-width:1024px) {
    html {
        font-family: 'OpenSans';
        font-size: 62.5%;
    }

    .wrap-container-serviceai section {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-direction: column;
        background-color: #000;
    }

    .serviceai-page-first {
        position: relative;
        background-size: cover;
        color: #fff;
        text-align: center;
    }
    .serviceai-page-first .video-bg {
        position: absolute;
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        z-index: 0;
    }

    .serviceai-page-first-contents {
        display: flex;
        margin-top: 22rem;
        flex-direction: column;
        align-items: center;
        z-index: 1;
    }

    .serviceai-page-first-text {
        width:85%;
    }

    #serviceai{
        position: relative;
        display: flex;
        flex-direction: row;
        width:90vw;
    }

    #serviceai .swiper-slide {
        width: 100%;
    }

    #serviceai .move-btn {
        display: none;
    }
    #serviceai .move-btn>img {
        width: 28px;
        height: 56px;
    }
    #serviceai .prev-btn {
        left: -10vw;
    }
    #serviceai .next-btn {
        right: -10vw;
    }
    #serviceai>.swiper-slide>.swiper-btn-container {
        visibility: visible;
        bottom: 2rem !important;
    }
    .swiper-btn-container {
        bottom: 0%;
    }
    /* #serviceai .serviceai-box:first-child {margin-left: 7px;} */
    #serviceai .serviceai-box{
        /* scroll-snap-align: start; */
        height: auto;
        margin-top: 180px;
        border-radius: 10px;
        background-color: #18191f;
        word-break: keep-all;
        min-width: calc(45vw - 10.7rem);
        margin: 18rem 1.5rem 8rem 0;
        padding: 5rem 4.6rem 0rem 4.6rem;
    }
    .serviceai-box>img {
        -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0.2)));
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2));
    }

    .serviceai-page-second {
        color: #fff;
        font-family: OpenSans;
        background: linear-gradient(173deg, #000 50%, #17073c 70%, #081482 90%);
        height: 100vh !important;
    }

    .serviceai-page-second p {
        margin-top: 190px;
        margin-bottom: 10px;
        font-size: 2.58rem;
        font-weight: bold;
    }

    .serviceai-page-second span {
        font-size: 1.16rem;
    }

    .serviceai-page-second>img {
        margin-top: 68px;
        width: 65vw;
        max-width: 1280px;
    }

    .serviceai-page-second>a {
        margin-top: 100px;
        width: 210px;
        height: 44px;
        border-radius: 22px;
        background-color: #fff;
        text-decoration: none;
        font-size: 0.77rem;
        font-weight: 600;
        text-align: center;
        line-height: 44px;
        color: #000;
        margin-bottom: 140px;
    }

    .serviceai-page-second {padding-top: 0;}
    .wrap-container-serviceai section.serviceai-page-first {
        padding-bottom: 22rem;
        height: auto !important;
    }

}

@media (min-width:701px)and(max-width:930px) {
    #serviceai .serviceai-box {
        padding: 3.5rem 3.2rem 0rem;
        min-width: (45vw - 7.9rem);
    }
    .serviceai-box .dyvity-logo {
        width: 22rem;
    }
}

@media (max-width:700px) {

    .wrap-container-serviceai section {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-direction: column;
        background-color: #000;
    }

    .serviceai-page-first {
        position: relative;
        background-size: cover;
        color: #fff;
        text-align: center;
    }

    .serviceai-page-first .video-bg {
        position: absolute;
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        z-index: 0;
    }

    .serviceai-page-first-contents {
        display: flex;
        margin-top: 12rem;
        flex-direction: column;
        align-items: center;
        z-index: 1;
    }

    .serviceai-page-first-text {
        width: 80vw;
    }

    .serviceai-page-first-text>p:nth-child(1) {
        font-size: 3.5rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    .serviceai-page-first-text>span:nth-child(2) {
        font-family: 'OpenSans';
        font-size: 1.8rem;
    }

    .serviceai-page-first-text>p:nth-child(3) {
        font-size: 1.4rem;
        margin-top: 6rem;
        margin-bottom: 2.5rem;
        word-break: keep-all;
        line-height: 1.7;
    }

    .serviceai-page-first-text>span:nth-child(4) {
        font-size: 1.4rem;
        word-break: keep-all;
        line-height: 1.7;
    }

    #serviceai {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 90vw;
    }

    #serviceai .move-btn {
        position: absolute;
        display: none;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        z-index: 2;
        bottom: 35vh;
    }

    #serviceai .move-btn>img {
        width: 28px;
        height: 56px;
    }

    #serviceai .prev-btn {
        left: -10vw;
    }

    #serviceai .next-btn {
        right: -10vw;
    }

    #serviceai .serviceai-box {
        /* scroll-snap-align: start; */
        display: flex;
        flex-direction: column;
        height: auto;
        margin: 11rem 1rem 8rem 0;
        min-width: calc(90vw - 10.2rem);
        text-align: left;
    }

    .serviceai-box>p {
        font-size: 3rem;
    }

    .serviceai-box>span {
        display: block;
        font-size: 1.4rem;
        line-height: 1.7;
    }

    .serviceai-box>span:nth-child(4) {
        color: #aeafc5;
        margin-bottom: 50px;
    }

    .serviceai-box>span:nth-child(5) {
    }

    .serviceai-page-second {
        color: #fff;
        font-family: OpenSans;
        background: linear-gradient(173deg, #000 50%, #17073c 70%, #081482 90%);
    }

    .serviceai-page-second p {
        margin-top: 190px;
        margin-bottom: 10px;
        font-size: 2.58rem;
        font-weight: bold;
    }

    .serviceai-page-second span {
        font-size: 4vw;
    }

    .serviceai-page-second>img {
        margin-top: 68px;
        width: 90vw;
        max-width: 1280px;
    }

    .serviceai-page-second>a {
        margin-top: 100px;
        width: 210px;
        height: 44px;
        border-radius: 22px;
        background-color: #fff;
        text-decoration: none;
        font-size: 0.77rem;
        font-weight: 600;
        text-align: center;
        line-height: 44px;
        color: #000;
        margin-bottom: 140px;
    }

    .serviceai-page-second .swiper-pagination button {
        font-size: 1.4rem;
        padding: 1rem 3.8rem;
    }
    .swiper-slide.opacity-swiper .slide > img {
        height: 34rem;
    }
    .serviceai-page-second .prev-btn {
        left: 32rem;
        top: 15rem;
    }
    .serviceai-page-second .next-btn {
        right: 32rem;
        top: 15rem;
    }

    .serviceai-page-second .serviceai-page-second-text span {
        font-size: 1.8rem;
    }
    .serviceai-page-second-text img {width: 16rem;}
    .serviceai-page-second .slide .logo-description img {width: 17.8rem;}
    .serviceai-page-second .slide .logo-description span {font-size: 1.6rem;}
}

@media (max-width:480px) {
    .serviceai-box .dyvity-logo{
        width: 22rem;
    }
    #serviceai .serviceai-box {
        min-width: calc(90vw - 7rem);
        padding: 5rem 3rem 0rem 3rem;
    }
    
}