    html{
        font-size: 62.5%;
        scroll-behavior: smooth;
    }
    .wrap-container-about.wrap-container-scrollpage {
        scroll-snap-type: none;
    }
    .wrap-container-about section{
        width:100%;
        height:100vh;
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    .about-page-first{
        background-image: url("../../images/about/about-bg.png");
        background-size: cover;
        background-color: #000;
        display:flex;
        justify-content:center;
        color: #fff;
        
    }
    .circle-box {
        position: relative;
        margin-bottom: 10rem;
    }
    .circle{
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        width: 50rem;
        height: 50rem;
        border-radius: 50%;
        border: 1px solid rgba(255,255,255,0.4);
    }
    .circle:nth-child(1) {
        bottom: -16rem;
        left: 50%;
        transform: translateX(-50%);
    }
    .circle:nth-child(2) {
        left: -47rem;
    }

    .circle p{
        text-align: center;
        font-size: 3.5rem;
        font-weight: bold;
    }
    .circle span{
        margin: 0 auto;
        margin-top: 4rem;
        width: 85%;
        text-align:center;
        font-size:1.8rem;
        word-break: keep-all;
        opacity: 0.7;
        line-height:1.67;
    }
    .about-page-second {
        display: flex;
        flex-direction: column;
        height: auto !important;
        /* background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgb(6, 0, 21) 60%); */
        background-color: #000;
        background-image: url(../../images/about/about-history-bg.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
    }
    .about-page-second>p {
        margin: 13rem auto;
        font-size: 5rem;
        font-weight:bold;
        color: #ffffff;
    }

    .history-box * {color: #ffffff;}
    .history-box {
        width:100%;
        position: relative;
        list-style: none;
        padding: 0;
        margin-bottom: 5rem;

    }
    .history-box>li {

    }
    .history-box>li:nth-child(2n){
        margin-left: 50%;
    }
    .history-box>li:nth-child(2n-1) {
        margin-right: 50%;
        text-align: end;
    }
    .history-box>div{
        position:absolute;
        height: 84%;
        margin-top:4rem;
        width: 1px;
        left:50%;
        background-color: #d1d1d1 !important;
        opacity: 0.3;
    }

    .history-box>li>dt {
        display: inline-block;
        font-size: 3.5rem;
        font-weight: bold;
        font-family: OpenSans;
        margin-bottom: 3rem;
    }
    .history-box>li:nth-child(2n)>dt::before {
        display: inline-block;
        content: " ";
        width: 7px;
        height: 7px;
        background-color: #d1d1d1 !important;
        margin-left: -3px;
        margin-right: 4.5vw;
        border-radius: 50%;
        opacity: 0.6;
    }
    .history-box>li:nth-child(2n-1)>dt::after {
        display: inline-block;
        content: " ";
        width: 7px;
        height: 7px;
        background-color: #d1d1d1 !important;
        margin-right: -4px;
        margin-left: 4.5vw;
        margin-bottom: 8px;
        border-radius: 50%;
        opacity: 0.6;
    }
    .history-box dd {
        /* min-width: 55rem; */
        font-size: 1.8rem;
        line-height: 2.22;
        margin: 0 5vw;
        opacity: 0.7;
    }
    .history-box>li:nth-child(2n)>dd::before {
        content: "· ";
    }
    .history-box>li:nth-child(2n-1)>dd::after {
        content: " ·";
    }
    .about-page-third {
        position: relative;
        background-color: #000;
        color: #fff;
    }
    .ci{
        position: absolute;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        word-break: keep-all;
        font-family: SpoqaHanSansNeo;
    }
    .ci>span{
        margin: auto;
        font-size: 3.5rem;
        font-weight: bold;
        margin-bottom: 4rem;
    }
    .ci>p{
        width: 25%;
        margin: 0 auto;
        opacity: 0.7;
        line-height: 1.67;
        font-size: 1.8rem;
        margin-bottom: 4.8rem;
    }
    .ci>p:nth-child(3) {font-family: OpenSans;}
    .about-page-fourth {
        background-color: #000;
        color: #fff;
    }
    .about-page-fourth>.ci>img{
        width:70rem;
        margin: 0 auto;
        padding-top: 2rem;
        padding-right: 2rem;
    }
    .about-page-fifth {
        flex-direction: column;
        font-family: OpenSans;
    }
    .about-page-fifth p{
        margin-top: 10vh;
        font-size: 2.58rem;
        font-weight: bold;
    }
    .about-page-fifth>ul {
        display: flex;
        width: 94%;
        flex-direction: row;
        justify-content: space-between;
        list-style: none;
        padding: 0;
    }
    .about-page-fifth>ul>li {
        display: flex;
        flex-direction: column;
        
    }
    .about-page-fifth>ul>li>img {
        width:888px;
        height:805px;
    }

	/* 로케이션 구역 추가 */
    .wrap-container-about .scroll-home-fourth {
        background-image: url("../../images/about/gradient_bg.png") !important;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .wrap-container-about .scroll-home-fourth .fourth-title {
        margin-top: 4rem;
    }

    .location-wrap iframe {
        width: 58.4rem;
        height: 52.9rem;
        margin-right: 17.6rem;
        border-radius: 15px;
    }
    .location-wrap img {
        border-radius: 15px;
    }

    .wrap-container-about .fourth-section li p {
        margin-bottom: 3rem;
    }
    .wrap-container-about .fourth-section li span {
        padding: 0;
    }
    .wrap-container-about .fourth-section li {
        border-bottom: 1px solid rgba(255,255,255,0.2);
        padding: 4rem 0;
        margin: 0 !important;
    }

@media screen and (max-width: 1920px) {
    .location-wrap iframe {
        width: 50rem;
        height: 50rem;
    }
}

@media (max-width:1024px) {

    html{
        font-size: 62.5%;
    }
    .wrap-container-about section {
        display: flex;
        align-items: center;
        overflow: hidden;
        height: auto;
    }

    .about-page-first {
        background-image: url("../../images/about/about-history-bg-tablet.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .circle-box {
        position: relative;
    }

    .circle {
        width: 47rem;
        height: 47rem;
    }

    .circle:nth-child(1) {
        bottom: -14rem;
        left: 50%;
        transform: translateX(-50%);
    }
    .circle:nth-child(2) {
        left: -45rem;
    }

    .circle:nth-child(3) {
        right: -45rem;
    }
    .circle p {
        font-size: 3.5rem;
    }

    .circle span {
        margin: 0 auto;
        margin-top: 3rem;
        font-size: 1.8rem;
        word-break: keep-all;
        opacity: 0.7;
        width: 75%;
    }

    .about-page-second {
        display: flex;
        flex-direction: column;
        height: auto !important;
    }

    .about-page-second>p {
        margin: 20rem auto 10rem;
    }

    .history-box {
        width: 100%;
        position: relative;
        list-style: none;
        padding: 0;
    }
		.history-box>div {
			left: 5.6rem;
			margin-top: 3rem;
			height: 83%;
		}
    .history-box>li:nth-child(2n) {
			margin-left: 6rem;
		}
		.history-box>li:nth-child(2n-1) {
			margin-right: 0;
			text-align: unset;
		}

    .history-box>li {
        margin: 0 60px 3rem;
        word-break: keep-all;
    }
    .history-box>li:nth-child(2n)>dt::before {
        width: 1rem;
        height: 1rem;
        position: absolute;
        left: -4.5rem;
        top: 38%;
    }
    .history-box>li:nth-child(2n-1)>dt::after {
        width: 1rem;
        height: 1rem;
        position: absolute;
        top: 38%;
        right: 12.2rem;
    }
    .history-box>li:nth-child(2n-1)>dd::after {
        display: none;
    }

    .history-box>li>dt {
        display: inline-block;
        font-size: 3.5rem;
        font-weight: bold;
        margin-bottom: 3rem;
				position: relative;
				margin-left: 4rem;
    }

    .history-box dd {
        font-size: 1.8rem;
        line-height: 2.2;
    }
    .history-box dd::before {
        content: "· ";
        margin-left:-1rem;
    }

    .about-page-third {
        position: relative;
        background-color: #000;
        color: #fff;
    }

    .ci {
        position: absolute;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        word-break: keep-all;
        font-family: SpoqaHanSansNeo;
    }

    .ci>span {
        margin-bottom: 4rem;
    }

    .ci>p {
        width: 55%;
    }

    .about-page-fourth {
        height:auto;
    }

    .about-page-fourth>.ci>img {
        width: 75%;
        margin: 0 auto;
    }

    .about-page-fifth {
        flex-direction: column;
        font-family: OpenSans;
    }

    .about-page-fifth p {
        margin-top: 10vh;
        font-size: 2.58rem;
        font-weight: bold;
    }

    .about-page-fifth>ul {
        display: flex;
        width: 94%;
        flex-direction: row;
        justify-content: space-between;
        list-style: none;
        padding: 0;
    }

    .about-page-fifth>ul>li {
        display: flex;
        flex-direction: column;
    }

    .about-page-fifth>ul>li>img {
        width: 888px;
        height: 805px;
    }

    .location-wrap iframe {
        margin-right: 0rem;
        margin-bottom: 4rem;
        width: 100%;
        height: 40rem;
    }

    .wrap-container-about .scroll-home-fourth {
        height: auto;
        padding-bottom: 10rem;
    }

}

@media (max-width:935px) {

	.circle {
		width: 38rem;
		height: 38rem;
	}
	.circle:nth-child(1) {
		bottom: -10rem;
	}
	.circle:nth-child(2) {left: -36rem;}
	.circle:nth-child(3) {right: -36rem;}

}

@media (max-width:760px) {

	.circle {
		width: 30rem;
		height: 30rem;
	}
	.circle:nth-child(1) {
		bottom: -9rem;
	}
	.circle:nth-child(2) {left: -29rem;}
	.circle:nth-child(3) {right: -29rem;}
	.circle span {font-size: 1.4rem;}
	.circle p {font-size: 3rem;}

    .location-wrap iframe {
        height: 35rem;
    }

}

@media (max-width:670px) {
    .location-wrap iframe {
        height: 30rem;
    }
}

@media (max-width:610px) {
    .location-wrap iframe {
        height: 30rem;
    }
}

@media (max-width:426px) {

    html{
        font-size: 62.5%;
    }
    .about-page-first {
        justify-content: center;
    }

    .circle-box {
        position: relative;
        margin-bottom: 10vh;
        width: 200vw;
        height: 80vh;
        overflow-x: scroll;
        scrollbar-width: none;
    }

    .circle-box::-webkit-scrollbar {
        display: none;
    }

    .circle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        width: 33rem;
        height: 33rem;
				bottom: 15rem !important;
    }

    .circle:nth-child(1) {
			left: 45%;
    }

    .circle:nth-child(2) {
			left: 75%;
		}

    .circle:nth-child(3) {
        left: 145%;
    }

    .circle:nth-child(3)::after {
        content: "　";
        margin-left: 73vw;
    }

    .circle p {
        text-align: center;
        font-size: 3rem;
    }

    .circle span {
        width: 67%;
        font-size: 1.4rem;
        word-break: keep-all;
        opacity: 0.4;
        line-height: 1.7;
				margin-top: 1.7rem;
    }
    .history-box>li {
        margin: 0 30px 3rem;
    }
        /* .about-page-second {
            display: flex;
            flex-direction: column;
        }
    
        .about-page-second>p {
            margin: 8vh auto;
            font-size: 2.58rem;
            font-weight: bold;
    
        }
    
        .history-box {
            width: 100%;
            position: relative;
            list-style: none;
            padding: 0;
    
        }
    
    
        .history-box>li {
            margin-left: 5%;
            margin-bottom: 4vw;
            word-break: keep-all;
        }
    
        .history-box>div {
            position: absolute;
            height: 452vw;
            margin-top: 8vw;
            width: 1px;
            left: 5%;
            background-color: #d1d1d1;
        }
    
        .history-box>li>dt {
            display: inline-block;
            font-size: 8vw;
            font-weight: bold;
            margin-bottom: 3vw;
        }
    
        .history-box>li::before {
            display: inline-block;
            content: " ";
            width: 7px;
            height: 7px;
            background-color: #d1d1d1;
            margin-left: -3px;
            margin-right: 4.5vw;
            border-radius: 50%;
        }
    
        .history-box dd {
            font-size: 4vw;
            line-height: 6vw;
            margin: 5vw 7vw;
    
        }
    
        .history-box dd::before {
            margin-left:-2.5vw;
            content: "· ";
        } */
        .about-page-second>p {
            font-size: 3.5rem;
            margin: 10rem auto 6rem;
        }
    
        .about-page-third {
            position: relative;
            background-color: #000;
            color: #fff;
        }
        .history-box>div {
            left: 4.6rem;
            height: 86%;
        }
        .history-box>li>dt {
            font-size: 3rem;
            margin-left: 2rem;
        }
        .history-box dd {
            font-size: 1.4rem;
            line-height: 1.7;
            margin-bottom: 2rem;
        }
        .history-box>li:nth-child(2n-1) {margin-left: 6rem;}
        .history-box>li:nth-child(2n)>dt::before {
            left: -3.5rem;
        }
        .history-box>li:nth-child(2n-1)>dt::after {
            right: 10.1rem;
        }
    
        .ci>span {
            font-size: 3rem;
            margin-bottom: 4rem;
        }
        .about-page-fourth > .ci > p:nth-child(2) {width: 78%;}
    
        .ci>p {
			width: 100%;
            margin: 1.1rem auto;
            line-height: 1.6rem;
            font-size: 1.4rem;
            line-height: 1.7;
        }
    
        .about-page-fourth {
            background-color: #000;
            color: #fff;
        }
    
        .about-page-fourth>.ci>img {
            padding-top: 14rem;
						width: 82%;
        }
    
        .about-page-fifth {
            flex-direction: column;
            font-family: OpenSans;
        }
    
        .about-page-fifth p {
            margin-top: 10vh;
            font-size: 2.58rem;
            font-weight: bold;
        }
    
        .about-page-fifth>ul {
            display: flex;
            width: 94%;
            flex-direction: row;
            justify-content: space-between;
            list-style: none;
            padding: 0;
        }
    
        .about-page-fifth>ul>li {
            display: flex;
            flex-direction: column;
        }
    
        .about-page-fifth>ul>li>img {
            width: 888px;
            height: 805px;
        }

        .wrap-container-about .fourth-section li {
            padding: 2rem 0;
        }

}

@media (max-width:400px){
    .circle-box {
        position: relative;
        margin-bottom: 10vh;
        width: 200vw;
        height: 100vh;
    }
} 