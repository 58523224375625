@font-face {
  font-family: 'SpoqaHanSansNeo';
  src: url('./fonts/SpoqaHanSansNeo_OTF_original/SpoqaHanSansNeo-Bold.otf');
  src: url('./fonts/SpoqaHanSansNeo_OTF_original/SpoqaHanSansNeo-Light.otf');
  src: url('./fonts/SpoqaHanSansNeo_OTF_original/SpoqaHanSansNeo-Medium.otf');
  src: url('./fonts/SpoqaHanSansNeo_OTF_original/SpoqaHanSansNeo-Regular.otf');
  src: url('./fonts/SpoqaHanSansNeo_OTF_original/SpoqaHanSansNeo-Thin.otf');
}

* {font-family: SpoqaHanSansNeo;}
p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.App {}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

  html{
    font-size: 62.5%;
    scroll-behavior: smooth;
  }
  .wrap-container {scroll-behavior: smooth;}
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }
  .gnb-wrap {
    position:fixed;
    width: 100%;
    height:8rem;
    min-height:60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0,0);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    z-index: 10;
  }

  .gnb-wrap img {
    width: 3.4rem;
    height: 3.8rem;
    margin-left: 6rem;
    object-fit: contain;
  }

  .gnb-menu{
    width: 36px;
    margin-right: 6rem;
    cursor: pointer;
  }
  .gnb-menu hr{
    height: 3px;
    border:none;
    background-color: #fff;
    margin-top: 8px;
    margin-left: auto;
    margin-right: 0;
  }
  .menubar-one {
    width: 36px;
    transition-duration: 0.5s;
  }
  .menubar-two {
    width: 23px;
    transition-duration: 0.5s;
  }
  .menubar-three {
    width: 36px;
  }
  .gnb-menu:hover .menubar-one{
    width: 16px;
    transition-duration: 0.5s;
  }
  .gnb-menu:hover .menubar-two {
    width: 26px;
    transition-duration: 0.5s;
  }
  .gnb-menuX {
    margin-right: 6rem;
    cursor: pointer;
    font-size: 4rem;
    font-weight: bold;
  }

  .rtate{
    transform: rotate(90deg);
    transition:0.5s;
  }

  .menu-page{
    position: fixed;
    visibility: hidden;
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.94);
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    z-index: 10;
    color: #fff;
    display:flex;
  }
  .menu-page>.gnb-wrap {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .menu-page>ul{
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content:space-around;
    margin:auto;
    width: 100%;
    position: absolute;
    top: 45%;
  }
  .menu-page>ul>li>a{
    text-decoration: none;
    font-family: OpenSans;
    font-weight: bold;
    font-size:2vw;
    color: #fff;
  }
  .menu-page>ul>li>span{
    float: right;
    height: 2.5vw;
    line-height: 2.5vw;
  }
  .menu-page>ul>li>span::before{
    font-family: OpenSans;
    font-weight: bold;
    width: 1.5vw;
    font-size: 1vw;
    color: #fff;
    content: "┼";
    padding-left: 1vw;
  }
  .menu-page>ul>li:hover .gnb-sub{
    visibility: visible;
    opacity: 1;
    transition: visibility 0.3s linear, opacity 0.5s;
  }
  .menu-page>ul>li:hover span::before{
    content: "━";
  }
  .gnb-sub{
    display: flex;
    visibility: hidden;
    /* position: absolute; */
    flex-direction: column;
    margin-top: 1vh;
    padding:0;
    list-style: none;
    opacity: 0;
    transition: 0.3s;
  }
  .gnb-sub>li{
    margin-top:2vh;
  }
  .gnb-sub>li>a{
    text-decoration: none;
    font-family: OpenSans;
    font-weight: normal;
    font-size: 1.6rem;
    color: #fff;
  }
  .gnb-sub>li>a:hover {
    color:#0c75ff;
  }
  .visible{
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }
  .wrap-container-scrollpage.wrap-container-home {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
  }
  .wrap-container-scrollpage section{
    scroll-snap-align: start;
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
  }
  .video-bg{
    position: absolute;
    width:100vw;
    height:100vh;
    overflow: hidden;
    margin: 0px auto;
  }
  .video-bg video{
    min-width: 100%;
    min-height: 100%;
    right: 0;
    bottom: 0;
  }
  #scroll-home-first{
    position:absolute;
    display: flex;
    align-items: center;
    z-index: 1;
    top:0;
  }
  .scroll-home-first-box{
    display: flex;
    width: 33.3%;
    height: calc(100vh - 8rem);
    margin-top: 8rem;
    align-items: center;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 0px;
    background-color:rgba(255, 255, 255, 0);
  }
  #scroll-home-first .scroll-home-second-xscroll > div:nth-child(1) {
    overflow: hidden;
  }
  .scroll-home-first-box:nth-child(1):hover {
    /* background: linear-gradient(160deg, rgba(0, 0, 1, 1) 60%, rgba(9, 19, 125, 1) 100%); */
    background-image: url(../public/Home/main-banner-bg.png);
    background-size: cover;
  }
  .scroll-home-first-box:nth-child(1) {
    position: relative;
    /* overflow: hidden; */
    z-index: 1;
  }
  .scroll-home-first-box .voucher-banner {
    margin-top: 0rem;
    width: 37%;
    position: absolute;
    z-index: 2;
    right: -3.5rem;
    top: -3.5rem;
    display: none;
  }
  .voucher-banner img {
    width: 100%;
  }
  .scroll-home-first-box:nth-child(2):hover {
    background: linear-gradient(340deg, rgba(30, 30, 38, 1) 0%, rgba(0, 0, 0, 1) 60%);
  }
  .scroll-home-first-box:nth-child(1):hover .voucher-banner {
    display: inline-block;
  }

  .box-hover-pic {
    width: 100%;
    display: none;
  }
  .scroll-home-first-box:nth-child(1) .box-hover-pic {margin-top: 9rem;}
  .scroll-home-first-box:nth-child(2) .box-hover-pic {margin-top: 6.4rem;}

  .box-hover-pic img {
    width: 100%;
  }
  .scroll-home-first-box:nth-child(1):hover .box-hover-pic {
    display: block;
  }
  .scroll-home-first-box:nth-child(2):hover .box-hover-pic {
    display: block;
  }
  .scroll-home-first-box:nth-child(3):hover .box-hover-pic {
    display: block;
  }

  .scroll-home-first-box:nth-child(3):hover {
    background-color: #000;
    background-image: url(../public/Home/first-box-pic3.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size:contain;
  }
  .scroll-home-first-box > div{
    width: 90%;
    margin-top: 38rem;
  }

  .scroll-home-first-box div a {
    background-color: #fff;
    color: #000110;
    border-radius: 22px;
    padding: 1.3rem 4.2rem;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0.035rem;
    text-decoration: none;
    display: none;
  }
  .scroll-home-first-box:nth-child(1):hover div a{display: inline-block;}
  .scroll-home-first-box:nth-child(2):hover div a{display: inline-block;}
  .scroll-home-first-box:nth-child(3):hover div a{display: inline-block;}

  .scroll-home-first-box p {margin: 3rem 0 6rem;}
  .scroll-home-first-box:nth-child(2) p {margin: 10rem 0 6rem;}

  .scroll-home-first-box span, .scroll-home-second-box span {
    display: block;
    font-family: OpenSans;
    font-size: 5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom: ;
    word-break: keep-all;
  }
  .scroll-home-second-box span {
    margin-bottom: 6rem;
  }
  .scroll-home-first-box p, .scroll-home-second-box p {
    font-family: SpoqaHanSansNeo;
    font-size: 1.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    word-break: keep-all;
  }

  .scroll-home-second-box p {
    font-size: 2.1rem;
  }
  .wrap-container-scrollpage section:first-child {
    background-color:#123;
  }
  .scroll-home-second{
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 6rem;
    display: flex;
    justify-content: space-between;
    background-color: rgba(2, 0, 36, 1);
    background: linear-gradient(145deg, rgba(2, 0, 36, 1) 50%, rgba(1, 40, 210, 1) 83%, rgba(77, 174, 229, 1) 100%);
    color: #fff;
  }

  .scroll-home-second-box {
    display: flex;
    width: 30%;
    padding-top: 32rem;
  }
  #explanation-5n {
    padding-top: 32rem;
    width: 55%;
  }
  .swiper-slide{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content:center;
  }
  .swiper-btn-container{
    display: flex;
    margin-top: 7.5vh;
    flex-direction: row;
    justify-content:center;
  }
  #scroll-home-first .swiper-btn-container {
    bottom: 15rem;
  }
  #explanation-5n > div > div.swiper-btn-container {
    bottom: 8rem;
  }
  .swiper-btn-container button{
    width: 10px;
    height: 10px;
    border: 1px solid #555;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 50%;
    padding:0;
    margin-right: 40px;
    cursor: pointer;
  }
  .swiper-btn-container button:last-child {
    margin-right: 0;
  }
  .selected{
    background-color: rgba(255, 255, 255, 1) !important;
  }
  .scroll-home-second-xscroll {
    display: flex;
    width: 100%;
    flex-direction: row;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
  }
  .move-btn{
    display:none;
  }
  .scroll-home-second-xscroll::-webkit-scrollbar {
    display: none;
  }
  .scroll-home-second-text {
    scroll-snap-align: start;
    display: flex;
    min-width: 100%;
    flex-direction: column;
  }
  .scroll-home-second-text div{
    margin-bottom:6rem;
  }
  .scroll-home-second-text span {
    font-family: OpenSans;
    font-size: 2.1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .scroll-home-second-text p {
    padding: 3rem 0 6rem 0;
    margin: 0;
    font-family: SpoqaHanSansNeo;
    font-size: 1.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    word-break: keep-all;
    opacity: 0.6;
  }
  .scroll-home-second-text hr {
    /* width: 52vw; */
    height: 1px;
    opacity: 0.3;
    background-color: #fff;
    border:none;
    margin: 0;
  }
  .scroll-home-third {
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #000;
  }
  .scroll-home-third p{
    margin: 18rem 0 10rem;
    font-family: OpenSans;
    font-size: 5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  #corp-company {
    display: flex;
  }
  .corp-wrap {
    scroll-snap-align: start;
    display: flex;
    width: 100%;
  }
  .corp-wrap ul {
    margin:0;
    padding:0;
    width: 47.5vw;
  }
  .corp-wrap ul:first-child {
    border-right: 1px solid rgba(255,255,255,0.2);
  }
  .corp-wrap ul:first-child li {
    justify-content: flex-end;
  }
  .corp-wrap li{
    display: flex;
    width: 47.5vw;
  }
  .corp-wrap li:first-child div {
    border-bottom:1px solid rgba(255, 255, 255, 0.2);
  }
  .corp-wrap li div:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
  .img-box{
    display: flex;
    width: 23.75vw;
    height: 30vh;
    max-width: 448px;
    max-height: 298px;
    background-color: #000;
  }
  .corp-wrap img{
    margin: auto;
    display: block;
    width:20rem;
    min-width: 1.5rem;
  }
  .scroll-home-fourth {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #02054a;
  }
  .fourth-title{
    margin-top: 15rem;
    text-align: center;
    font-family: OpenSans;
    font-size: 5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #fff;
  }

  .location-wrap{
    scroll-snap-align: start;
  }
  .location-wrap img{
    width: 58.4rem;
    margin-right: 17.6rem;
  }
  .location-btns {
    text-align: center;
    margin-top: 6rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  .location-btns button {
    outline: none;
    box-shadow: none;
    border: none;
    background: none;
    font-family: OpenSans;
    font-size: 1.8rem;
    width: 20rem;
    height: 5rem;
    border: 1px solid #fff;
    color: #fff;
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
  }
  .location-btns button:first-child {margin-right: 3rem;}
  .location-btns button:last-child {opacity: 0.3;}

  .info-box{
    display: none;
    flex-direction: row;
    align-items: center;
    margin: 8rem auto ;
  }
  .l-headquarters {display: flex;}

  .info-box ul{
    display: flex;
    flex-direction: column;
    margin:0;
    padding: 0;
    list-style: none;
    font-family: SpoqaHanSansNeo;
  } 
  .info-box ul ul{
    padding: 0;
    font-size:1vw;
    color:#fff;
  }
  .info-box ul ul li:nth-child(2) {
    margin: 3rem 0;
  }
  .info-box ul li p{
    color: #fff;
    font-size:2.1rem;
    font-weight: bold;
    margin: 0;
  }
  .info-box ul li span {
    display: block;
    font-size: 1.8rem;
    color: #aeafc5;
    line-height:1.67;
    padding: 3rem 0 6rem 3.2rem;
  }
  .info-box ul li:first-child span {
    /* border-left: 2px dashed rgba(255, 255, 255, 0.2); */
  }
  .info-box ul ul li{
    font-size: 1.8rem;
  }

  footer {
    scroll-snap-align: start;
    background-color: #000;
    color: #fff;
    font-size: 0.8vw;
    font-family: SpoqaHanSansNeo;
  }
  footer div:nth-child(2) {
    display: flex;
  }
  .footer-box {
    display: flex;
    padding: 5rem 6rem 8rem 6rem;
    background-color: #000;
    color: rgba(255,255,255,0.6);
    font-weight: 300;
  }
  .footer-box strong, .footer-box p {
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }
  .logo-box{
    margin-right: 8rem;
  }
  .footer-box ul{
    list-style: none;
    margin: 0;
    padding: 0 8.2rem;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }
  
  .footer-box ul span {font-size: 1.4rem;}
  .footer-box ul:last-child {
    border: none;
  }
  .contact-box>li {
    margin-bottom:1rem;
    font-size: 1.4rem;
  }
  .contact-box>li:last-child {
    margin-bottom: 0;
  }
  .contact-box .contact-list {padding: 0;}
  .contact-list li:nth-child(2) {margin: 1rem 0;}
  footer .family-site .dropdown {
    position: relative;
    display: inline-block;
    width: 20rem;
    margin-top: 2rem;
  }

  .family-site .dropdown .dropbtn {
    background-color: #101010;
    color: #fff;
    font-size: 1.4rem;
    width: 100%;
    height: 5rem;
    padding: 0rem 2rem;
    text-align: left;
    border: 1px solid #101010;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.035rem;
  }
  .family-site .dropdown .dropbtn img.rotate {
    transform: rotate(180deg);
    transition: 0.3s all;
  }
  .family-site .dropdown .dropdown-content {
    position: absolute;
    z-index: 900;
    left: 0;
    bottom: 5rem;
    padding: 0rem;
    display: none;
  }
  .family-site .dropdown .dropdown-content.show {
    display: block;
  }
  .family-site .dropdown .dropdown-content li {
    background-color: #101010;
    height: 5rem;
    line-height: 5rem;
  }
  .family-site .dropdown .dropdown-content li:hover {background-color: #0c75ff;}
  .family-site .dropdown .dropdown-content li:first-child {
    border-radius: 5px 5px 0px 0px;
  }
  .family-site .dropdown .dropdown-content a {
    display: inline-block;
    color: #fff;
    font-size: 1.4rem;
    letter-spacing: 0.035rem;
    text-decoration: none;
    width: 16rem;
    padding: 0rem 2rem;
  }

  .footer-bottom {
    padding: 1rem 6rem;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.4);
    font-size: 1.4rem;
    background-color: #101010;
  }

  .top-btn {
    display: none;
    position: fixed;
    z-index: 9;
    bottom: 6rem;
    right: 10rem;
    border: none;
    background:none;
    width: 4.4rem;
    height: 4.4rem;
    cursor: pointer;
}

  @media screen and (max-width:1920px) {
    .scroll-home-first-box > div {
      margin-top: 15rem;
    }
    .scroll-home-first-box:nth-child(2) .box-hover-pic {
      margin-top: 3rem;
    }
    .scroll-home-first-box p, .scroll-home-first-box:nth-child(2) p {
      margin-bottom: 4rem;
    }
    .scroll-home-first-box .voucher-banner {
      width: 37%;
      right: -2.7rem;
      top: -2.5rem;
    }

    .scroll-home-second-box,#explanation-5n {
      padding-top: 20rem;
    }

    .scroll-home-third p {
      margin: 16rem 0 8rem;
    }
    
    .img-box {
      width: 21.75vw;
      height: 26vh;
    }
    .scroll-home-fourth {
      padding: 0 3rem;
    }

    .location-wrap img {
      width: 50rem;
    }

  }

  @media screen and (max-width:1024px) {
    html{
      font-size: 62.5%;
    }
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }
  
    .App-link {
      color: #61dafb;
    }
    .wrap-container-scrollpage.wrap-container-home {
      scroll-snap-type:unset;
    }
    .gnb-wrap {
      position:fixed;
      width: 100%;
      height:8rem;
      min-height:60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(0, 0, 0,0);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      color: #fff;
      z-index: 10;
    }
    .gnb-wrap img {
      width: 3.4rem;
      height: 3.8rem;
      margin-left: 6rem;
      object-fit: contain;
    }
  
    .gnb-menu {
      width: 3.6rem;
      margin-right: 6.2rem;
      cursor: pointer;
    }
    
    .menubar-one {
      width: 3.6rem;
      transition-duration: 0.3s;
    }
  
    .menubar-two {
      width: 2.3rem;
      transition-duration: 0.2s;
    }
  
    .menubar-three {
      width: 3.6rem;
    }
  
    .gnb-menu:hover .menubar-one {
      width: 1.6rem;
      transition-duration: 0.3s;
    }
  
    .gnb-menu:hover .menubar-two {
      width: 2.6rem;
      transition-duration: 0.2s;
    }
  
    .gnb-menuX {
      margin-right: 6rem;
      cursor: pointer;
      font-size: 4rem;
      font-weight: bold;
    }
  
    .menu-page>ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      position: absolute;
      top: 11.2rem;
    }
    .menu-page>ul>li {
      margin-bottom: 4rem;
      position: relative;
    }
  
    .menu-page>ul>li a {
      text-decoration: none;
      font-family: OpenSans;
      font-weight: bold;
      font-size: 4rem;
      color: #fff;
    }
  
    .menu-page>ul>li>span {
      position: absolute;
      top: 1.5rem;
      color: #fff;
      font-family: OpenSans;
      font-weight: bold;
      font-size: 1.5rem;
      margin-left: 2rem;
    }
  
    /* .menu-page>ul>li>span::after {
      position: absolute;
      content: "┼";
      padding-left: 2rem;
      bottom:0.9vw;
    } */
  
    .menu-page>ul>li:hover .gnb-sub {
      visibility: visible;
      height: auto;
      transform: scaleY(1);
      margin-top: 3rem;
    }
  
    /* .menu-page>ul>li:hover span::after {
      content: "━";
    } */
  
    .gnb-sub {
      visibility: hidden;
      height: 0;
      flex-direction: column;
      padding: 0;
      list-style: none;
      transform: scaleY(0);
      transform-origin: 0px 0px;
      transition: transform 0.3s ;
    }
  
    .gnb-sub>li {
      padding: 1rem 0;
    }
  
    .gnb-sub>li>a {
      font-weight: normal !important;
      font-size: 1.6rem !important;
    }
  
    .gnb-sub>li>a:hover {
      color: #0c75ff;
    }
    /* 태블릿, 모바일 포지셔닝 기능 삭제 */
    .wrap-container-scrollpage {
      scroll-snap-type: unset;
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100vh;
    }

    #root > div > section:nth-child(3) {
      height: 100vh;
  }

    .swiper-btn-container {
      bottom: 9rem !important;
    }
    #scroll-home-first > div > div.swiper-btn-container {bottom: 17rem !important;}
    .recruit-second>header>p {
        margin: 4rem auto 10rem;
    }
  
    .wrap-container-scrollpage section {
      scroll-snap-align:unset;
      width: 100%;
      height: auto;
      display: flex;
      position: relative;
      align-items: center;
      overflow: hidden;
    }
  
    .wrap-container-scrollpage .video-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: 0 auto;
      z-index: 0;
    }
  
    .video-bg video {
      min-width: 100%;
      min-height: 100%;
      right: 0;
      bottom: 0;
    }
  
    .scroll-home-first-box {
      width: 100%;
      height: 100vh;
      border: 0px;
      position: relative;
    }

    #scroll-home-first .scroll-home-second-xscroll div:nth-child(1) {overflow: unset;}
    
    .scroll-home-first-box:nth-child(1) {
      background-image: url(../public/Home/main-banner-bg.png);
      background-size: cover;
    }
    .scroll-home-first-box:nth-child(2) {
      background:none !important;
    }
    .scroll-home-first-box:nth-child(3) {
      background-color: #000;
      background-image: url(../public/Home/first-box-pic3.png);
      background-repeat: no-repeat;
      background-position: bottom;
      background-size:contain;
    }
    .scroll-home-first-box div {
      width: 100vw;
      padding-left: 6rem;
    }
    .scroll-home-first-box > div {
      margin-top: 20rem;
    }
    .scroll-home-first-box p, .scroll-home-first-box:nth-child(2) p {margin-bottom: 7rem;}
    .scroll-home-first-box:nth-child(3) p {width: 65%;}
    .scroll-home-second-box {width: 80%;}
    .scroll-home-first-box span,
    .scroll-home-second-box span {
      display: block;
      font-family: OpenSans;
      font-size: 5rem;
      font-weight: bold;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      word-break: keep-all;
    }
    .scroll-home-first-box span{
      min-height:125px;
    }

    .box-hover-pic {
      padding-left: 0 !important;
      position: relative;
      right: 5%;
      display: none;
    }
    .scroll-home-first-box div a {display: inline-block;}
    .scroll-home-first-box .voucher-banner {
      display: inline-block;
      padding-left: 0;
      width: 35%;
      right: -3.7rem;
      top: -3rem;
    }
    .scroll-home-first-box:nth-child(1):hover .box-hover-pic, 
    .scroll-home-first-box:nth-child(2):hover .box-hover-pic {display: none;}
    
    .scroll-home-second-box span {
      margin-bottom:5rem;
    }

    .scroll-home-second-box p {
      margin-top: 0;
    }
  
    .scroll-home-second {
      display: flex;
      flex-direction: column;
      justify-content:initial;
      width: 100%;
      height: 100%;
      background-color: rgba(2, 0, 36, 1);
      background: linear-gradient(145deg, rgba(2, 0, 36, 1) 50%, rgba(1, 40, 210, 1) 83%, rgba(77, 174, 229, 1) 100%);
      color: #fff;
      padding: 0 6rem 16rem;
    }
    
    .scroll-home-second-box {
      display: flex;
      padding-top: 16rem;
    }
  
    #explanation-5n {
      margin: auto;
      width: 84vw !important;
      padding-top: 0rem;
      margin-top: 15rem;
    }
  
    .swiper-btn-container {
      display: flex;
      margin-top: 7.5vh;
      flex-direction: row;
      justify-content: center;
      position: absolute;
      z-index: 9;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
    }
  
    .swiper-btn-container button {
      width: 10px;
      height: 10px;
      border: 1px solid #fff;
      background-color: rgba(255, 255, 255, 0);
      border-radius: 50%;
      padding: 0;
      margin-right: 40px;
    }
    .swiper-btn-container button:last-child {
    margin-right: 0;
    }
    .move-btn{
      display:none;
    }
    .selected {
      background-color: rgba(255, 255, 255, 1) !important;
    }
  
    .scroll-home-second-xscroll {
      display: flex;
      width: 100%;
      flex-direction: row;
      overflow-x: scroll;
      scrollbar-width: none;
      scroll-snap-type: x mandatory;
    }
  
    .scroll-home-second-xscroll::-webkit-scrollbar {
      display: none;
    }
  
    .scroll-home-second-text {
      scroll-snap-align: start;
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .scroll-home-second-text div:last-child {
      margin-bottom: 0;
    }
    
    .scroll-home-second-text hr {
      width: 100%;
      height: 1px;
      opacity: 0.3;
      background-color: #fff;
      border: none;
      margin: 0;
    }
  
    .scroll-home-third {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: auto;
      background-color: #000;
      padding-bottom: 14rem;
    }
  
    .scroll-home-third p {
      margin: 10rem 0rem 6rem;
      font-family: OpenSans;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  
    #corp-company {
      display: flex;
      width: 80vw !important;
  
    }
  
    .corp-wrap {
      scroll-snap-align: start;
      display: flex;
      flex-direction: column;
      flex-flow: row wrap;
      width: 100%;
    }
  
    .corp-wrap ul {
      margin: 0;
      padding: 0;
      width: 80vw;
    }
  
    .corp-wrap ul:first-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      border-right: 0px;
    }

    .corp-wrap li {
      display: flex;
      width: 80vw;
    }
  
    .corp-wrap li:first-child div {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  
    .corp-wrap li div:first-child {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
  
    .img-box {
      display: flex;
      width: 40vw;
      max-width: 448px;
      height: 16vh;
      max-height: 298px;
      background-color: #000;
    }
  
    .corp-wrap img {
      margin: auto;
      display: block;
      width: 19vw;
      min-width: 120px;
    }
  
    .scroll-home-fourth {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      background-color: #02054a;
    }
  
    .fourth-title {
      margin-top: 10vh;
      text-align: center;
      font-family: OpenSans;
      font-size: 5rem;
      line-height: 1.4;
      color: #fff;
    }
    .location-btns {margin-top: 5rem;}

    .location-5n {width: 55%;}
  
    .location-wrap {
      scroll-snap-align: start;
    }
  
    .location-wrap img {
      margin-right: 0rem;
      margin-bottom: 4rem;
      width: 100%;
    }
  
    .info-box {
      display: flex;
      flex-direction: column;
      margin: 0;
      margin-top: 4rem;
    }
    .info-box.l-r-d-space {
      display: none;
    }
    .info-box ul {
      margin: 0;
      padding: 0rem 4rem 10rem;
      list-style: none;
      font-family: SpoqaHanSansNeo;
    }
  
    .info-box ul ul {
      padding: 0;
      font-size: 2vw;
      color: #fff;
    }
    .info-box ul ul li{
    }
  
    .info-box ul ul li:nth-child(2) {
      margin: 2.2rem 0;
    }
  
    .info-box ul li span {
      display: block;
      color: #aeafc5;
      line-height: 1.5;
      word-break: keep-all;
      padding: 2rem 0 3rem 3.2rem;
    }
  
    .info-box ul li:first-child span {
    }
  
    .footer-box {
      display: flex;
      flex-direction: column;
      padding: ;
      background-color: #000;
      color: rgba(255, 255, 255, 0.6);
      font-weight: 300;
    }
  
    .logo-box {
      margin-bottom: 5rem;
    }
  
    .footer-box ul {
      list-style: none;
      margin: 0;
      margin-top: 8rem 6rem 5.6rem;
      border-right: 0;
      padding: 0rem;
    }
    .footer-box ul:nth-child(2) {
      margin: 3rem 0 4.6rem;
    }

    .footer-box ul:last-child {
      display: flex;
      align-items: flex-start;
      position: relative;
    }
    
    .contact-box>li {margin-bottom: 0;}
    .contact-list li:nth-child(2) {margin: 0rem;}
    .contact-box{
      display: flex;
      justify-content: space-between;
    }

    .contact-box .contact-list li {
      margin-right: 2rem;
    }

    footer div:nth-child(2) {
      flex-flow: column wrap;
    }
    footer .family-site .dropdown {
      margin-top: 0;
    }
    .family-site .dropdown .dropdown-content {bottom: 0;}

    .top-btn {
      bottom: 4rem;
      right: 4rem;
  }
    .top-btn img {
      display: block;
      width: 4rem;
      height: 4rem;
    }

  }

@media screen and (max-width:850px) {
  .location-5n {
    width: 65%;
  }
}

@media screen and (max-width:768px) {

  .scroll-home-second-box {padding-top: 14rem;}
  .scroll-home-second-box span {margin-bottom: 4rem;}
  .scroll-home-second-text p {
    padding: 1rem 0 4rem 0;
  }
  .scroll-home-first-box .voucher-banner {
    right: -3rem;
    top: -2.7rem;
  }

}

@media screen and (max-width:580px){
  .scroll-home-first-box .voucher-banner {
    right: -2.6rem;
    top: -1.7rem;
  }
  .location-5n {
    width: 96%;
  }
  .info-box ul {
    padding: 0rem 0rem;
  }

}

@media screen and (max-width:426px) {
  /* html{
    font-size:13.5px;
  } */
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  .gnb-wrap {
    position: fixed;
    width: 100%;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
    z-index: 10;
  }


  .gnb-wrap img {
    width: 3.4rem;
    margin-left:3rem;
    object-fit: contain;
  }

  .gnb-menu {
    margin-right: 3rem;
    cursor: pointer;
  }

  .menubar-one {
    width: 36px;
    transition-duration: 0.5s;
  }

  .menubar-two {
    width: 23px;
    transition-duration: 0.5s;
  }

  .menubar-three {
    width: 36px;
  }

  .gnb-menu:hover .menubar-one {
    width: 16px;
    transition-duration: 0.5s;
  }

  .gnb-menu:hover .menubar-two {
    width: 26px;
    transition-duration: 0.5s;
  }

  .gnb-menuX {
    margin-right: 3rem;
    cursor: pointer;
    font-size: 4rem;
  }

  .rtate {
    transform: rotate(90deg);
    transition: 0.5s;
  }

  .menu-page {
    position: fixed;
    visibility: hidden;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    z-index: 10;
    color: #fff;
    display: flex;
  }

  .menu-page>.gnb-wrap {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .menu-page>ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 4rem 0rem 0rem 3rem;
  }

  .menu-page>ul>li {
    margin-bottom: 5vh;
    position: relative;
  }

  .menu-page>ul>li a {
    font-size: 3.5rem;
  }

  .menu-page>ul>li>span {
    position: absolute;
    top: 1.5rem;
    color: #fff;
    font-family: OpenSans;
    font-weight: bold;
    font-size: 4vw;
    margin-left: 2rem;
  }

  .menu-page>ul>li>span::before {
    padding-left: 0;
  }
  .menu-page>ul>li:hover>span::before {
    content: "━";
  }

  /* .menu-page>ul>li>span::after {
    position: absolute;
    content: "┼";
    padding-left: 2vw;
    bottom: 1.8vw;
  } */

  .menu-page>ul>li:hover .gnb-sub {
    visibility: visible;
    height: auto;
    transform: scaleY(1);
    margin-top: 3vh;
  }

/*   .menu-page>ul>li:hover span::after {
    content: "━";
  } */

  .gnb-sub {
    visibility: hidden;
    height: 0;
    flex-direction: column;
    padding: 0;
    list-style: none;
    transform: scaleY(0);
    transform-origin: 0px 0px;
    transition: transform 0.3s;
  }

  .gnb-sub>li {
    padding: 0.9vh 0;
  }

  .gnb-sub>li>a {
    font-weight: normal !important;
    font-size: 4vw !important;

  }

  .gnb-sub>li>a:hover {
    color: #0c75ff;
  }

  .visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  /* 태블릿, 모바일 포지셔닝 기능 삭제 */
  .wrap-container-scrollpage {
    scroll-snap-type: unset;
  }

  .swiper-btn-container {
    bottom: 4rem !important;
  }
  #scroll-home-first > div > div.swiper-btn-container {bottom: 5rem !important;}

  .wrap-container-scrollpage section {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
  }
  #root > div > section:nth-child(3) {
    height: 100vh;
  }

  .video-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0px auto;
  }

  .video-bg video {
    min-width: 100%;
    min-height: 100%;
    right: 0;
    bottom: 0;
  }

  #scroll-home-first {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 0;
  }

  .scroll-home-first-box {
    scroll-snap-align: start;
    display: flex;
    width: 100vw;
    height: calc(100vh - 8rem);
    margin-top: 8rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0);
  }

  .scroll-home-first-box div {
    width: 100%;
    margin-top: -15rem;
  }
  .scroll-home-first-box span {height: unset;text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.7);}
  .scroll-home-first-box p {
    margin: 3rem 0 6rem;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 1);
  }
  .scroll-home-first-box span,
  .scroll-home-second-box span {
    width: 90vw;
    display: block;
    height: 25vw;
    min-height:0px;
    font-family: OpenSans;
    font-size: 3.5rem;
    line-height: 1.37;
    word-break: keep-all;

  }
  .scroll-home-second-box span {
    font-size:3rem;
    width : auto;
    height: unset;
  }

  .scroll-home-first-box p {width: 85% !important;}
  .scroll-home-first-box:nth-child(2) p {margin-top: 2.5rem;}

  .scroll-home-first-box p,
  .scroll-home-second-box p {
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    word-break: keep-all;
  }
  .scroll-home-second-box p {
    width:auto;
    line-height: 1.71;
    font-size: 1.4rem;
  }
  .scroll-home-first-box .voucher-banner {
    margin: 0;
    width: 40%;
    right: -1.8rem;
    top: -3.2rem;
  }

  .wrap-container-scrollpage section:first-child {
    background-color: #123;
  }

  .scroll-home-second {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 0;
    padding-bottom: 10rem;
    background: linear-gradient(110deg, rgba(2, 0, 36, 1) 50%, rgba(1, 40, 210, 1) 83%, rgba(77, 174, 229, 1) 100%);
  }

  .scroll-home-second-box {
    display: flex;
    padding-top: 9.5rem;
    padding-left: 3rem;
    padding-bottom: 7rem;
  }

  #scroll-home-first .swiper-btn-container {
    bottom: 5rem;
  }
  #explanation-5n > div > div.swiper-btn-container {
    bottom: 5rem;
}

  #explanation-5n {
    width: 90vw !important;
    margin: 0 3rem;
  }

  .swiper-slide {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .swiper-btn-container {
    display: flex;
    margin-top: 7.5vh;
    flex-direction: row;
    justify-content: center;
    bottom: 5rem !important;
  }

  .swiper-btn-container button {
    width: 10px;
    height: 10px;
    border: 1px solid #555;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 50%;
    padding: 0;
    margin-right: 40px;
  }

  .swiper-btn-container button:last-child {
    margin-right: 0;
  }

  .selected {
    background-color: rgba(255, 255, 255, 1) !important;
  }

  .scroll-home-second-xscroll {
    display: flex;
    width: 100%;
    flex-direction: row;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
  }

  .scroll-home-second-xscroll::-webkit-scrollbar {
    display: none;
  }

  .scroll-home-second-text {
    scroll-snap-align: start;
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .scroll-home-second-text div {
    width: 90vw;
    margin-bottom: 3rem;
  }

  .scroll-home-second-text div:last-child {
    margin-bottom: 0;
  }

  .scroll-home-second-text span {
    font-size: 1.4rem;
    line-height: 1.19;
  }

  .scroll-home-second-text p {
    width: 86vw;
    font-family: SpoqaHanSansNeo;
    font-size: 1.2rem;
    padding: 2rem 0 3rem;
    line-height: 1.83;
  }

  .scroll-home-second-text hr {
    width: 87vw;
    height: 1px;
    opacity: 0.3;
    background-color: #fff;
    border: none;
    margin: 0;
  }

  .scroll-home-third {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  .scroll-home-third p {
    margin-top: 10rem;
    margin-bottom: 2rem;
    font-size: 3rem;
  }
  .scroll-home-second-xscroll {
    margin-top: 2.6rem;
  }
  #corp-company {
    display: flex;
    width: 80vw !important;

  }

  .corp-wrap {
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    width: 100%;
    height: fit-content;
  }

  .corp-wrap ul {
    margin: 0;
    padding: 0;
    width: 80vw;
  }

  .corp-wrap ul:first-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }



  .corp-wrap li {
    display: flex;
    width: 80vw;
  }

  .corp-wrap li:first-child div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .corp-wrap li div:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }

  .img-box {
    display: flex;
    width: 40vw;
    max-width: 448px;
    height: 16vh;
    max-height: 298px;
    background-color: #000;
  }

  .corp-wrap img {
    margin: auto;
    display: block;
    width: 30vw;
    min-width: 80px;
  }

  .scroll-home-fourth {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #02054a;
  }
  .fourth-title {
    margin-top: 10rem;
    text-align: center;
    font-family: OpenSans;
    font-size: 3.5rem;
    line-height: 1.4;
  }
  .location-btns {
    margin: 3rem 0;
  }
  .location-btns button {
    font-size: 1.2rem;
    width: 14.5rem;
    height: 4rem;
  }
  .location-btns button:first-child {margin-right: 2rem;}
  .location-5n {
    margin: 0 auto;
    width: 100%;
  }
  .info-box ul ul li {font-size: 1.4rem;}
  .location-wrap {
    scroll-snap-align: start;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .info-box>ul {
    width: 100%;
  }
  .info-box>ul>li:first-child{
   /*  display: none; */
  }

  .info-box>ul>ul {
    height: unset;
  }

  .info-box>ul>li p {
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    margin: 0;
  }

  .info-box>ul>li span {
    display: block;
    padding: 2rem 0rem 3rem 2.5rem;
    font-size: 1.2rem;
    line-height: 1.67;
  }

  .info-box ul li:first-child span {
    /* border-left: 2px dashed rgba(255, 255, 255, 0.2); */
  }


  footer {
    word-break: keep-all;
  }

  .footer-box {
    display: flex;
    flex-direction: column;
    font-weight: 300;
    padding: 4rem 0rem 3rem 0rem;
  }

  .logo-box {
    margin-left: 3rem;
    margin-bottom: 4rem;
  }

  .footer-box ul {
    list-style: none;
    margin: 0;
    padding: 0rem 3rem;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }
  .footer-box ul:nth-child(2) {
    margin: 0;
    padding: 3rem 3rem;
  }

  .footer-box strong, .footer-box p, .footer-box ul span, .contact-box>li {
    font-size: 1.2rem;
    line-height: 1.83;
  }
  .footer-box strong {color: rgba(255, 255, 255, 0.8);}
  .footer-box p {margin-bottom: 0.5rem;}

  .contact-box {
    display: flex;
    flex-direction: column;
  }

  .contact-box>li {
    margin-bottom:3rem;
  }
  .contact-list {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
  }
  .contact-list li:nth-child(2) {margin: 1rem 0rem;}
  .footer-bottom {
    padding: 1rem 0 1rem 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.4);
    font-size: 1.2rem;
    background-color: #101010;
  }
  .contact-box>li:last-child {width: 100%;}
  footer .family-site .dropdown {
    width: 100%;
  }
  footer .family-site .dropdown span {
    font-size: 1.4rem;
  }
  .family-site .dropdown .dropdown-content {
    bottom: 5rem;
    width: 100%;
  }

  .top-btn {
    bottom: 3rem;
    right: 3rem;
}

}

@media screen and (max-width:400px) {
  .scroll-home-first-box .voucher-banner {
    right: -1.7rem;
    top: -2.8rem;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
