.swiper-slide {width: 100%;}
.recruit {
    width: 100%;
    height: auto;
}

.recruit section {
    display:flex;
    flex-direction: column;
    width: 100%;
    /* min-height: 100vh; */
    height: auto;
    background-color: #000;
    color: #fff;
    word-break: keep-all;
    padding-bottom: 27rem;
}

.recruit>section>header {
    text-align: center;
    padding-top: 26rem;
}

.recruit>section>header>span {
    font-size: 5rem;
    font-weight: bold;
    font-family: OpenSans;
}

.recruit-first>header>p {
    font-size: 2.1rem;
    line-height: 1.6;
    margin: 4rem auto 0;
}
.recruit-second>header>p {
    font-size: 2.1rem;
    line-height: 1.6;
    margin: 4rem auto 10rem;
}
#talent{
    margin: 100px auto 0;
}
#talent .talent-box {
    height: 49.6rem;
    min-width: 30rem;
    max-width: 35.2rem;
    margin: 0 19px;
    padding: 5rem 4rem 0;
    border-radius: 10px;
    background-color: rgba(24, 25, 31, 0.5);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    word-break: keep-all;
}

#talent .swiper-btn-container{
    display:none
    
}
.talent-box>img {
    width: 35rem;
    min-width: 250px;
    border-radius: 8px;
}

.talent-box>p {
    margin: 8rem 0 4rem;
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
}

.talent-box>span {
    display: block;
    opacity: 0.7;
    font-family: SpoqaHanSansNeo;
    text-align: left;
    line-height: 1.63;
    font-size: 1.6rem;
}
@media (min-width: 1025px) and (max-width: 1450px) {
    #talent .talent-box {
        min-width: 24rem;
        width: 24rem;
        margin: 0 0 0 2rem;
        padding: 5rem 3rem 0;
    }
    .talent-box>img {
        width: 24rem;
        min-width: 24rem;
        border-radius: 8px;
    }
}
#careers {
    margin: 100px auto 0;
}

#careers .talent-box {
    width: 22vw;
    height: auto;
    min-width: 300px;
    min-height: 510px;
    margin: 0 19px;
    border-radius: 10px;
    background-color: #18191f;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    word-break: keep-all;
    font-size: calc(0.6rem + 0.3vw);
}

#careers .swiper-btn-container {
    display: none
}
#careers .careers-box {
    height: 49.6rem;
    min-width: 30rem;
    max-width: 35.2rem;
    margin: 0 19px;
    padding: 5rem 4rem 0;
    border-radius: 10px;
    background-color: rgba(24, 25, 31, 0.5);
    word-break: keep-all;
    font-size: calc(0.6rem + 0.3vw);
}
.careers-box>img {
    width: 35rem;
    min-width: 250px;
    border-radius: 8px;
}

.careers-box>p {
    min-width: 220px;
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    font-family: OpenSans;
    margin: 8rem 0 4rem;
}

.careers-box>span {
    display: block;
    min-width: 220px;
    opacity: 0.7;
    font-family: SpoqaHanSansNeo;
    font-size: 1.6rem;
    text-align: left;
    line-height: 1.63;
}
@media (min-width: 1025px) and (max-width: 1450px) {
    #careers .careers-box {
        min-width: 24rem;
        width: 24rem;
        margin: 0 0 0 2rem;
        padding: 5rem 3rem 0;
    }
    #careers .careers-box p{
        font-size: 2.4rem;
        margin: 3rem 0 4rem;
    }
    .careers-box>img {
        width: 24rem;
        min-width: 24rem;
        border-radius: 8px;
    }
    #welfare-box .welfare {
        width: 30rem;
        height: 30rem;
        border-radius: 10px;
        text-align: center;
        margin: 1.5rem;
    }

}

.recruit-thrid {
    background-image: url('../../images/Recruit/bg.png');
    background-size: cover;
}
.recruit-thrid p {
    font-size: 2.1rem;
    margin-top: 4rem;
}

#welfare-box{
    width: 76vw;
    margin: 50px auto;
}
#welfare-box .swiper-btn-container{
    display: none;
}
#welfare-box .scroll-home-second-xscroll{
    flex-flow: row wrap;
}
.welfare{
    width: 432px;
    height: 300px;
    margin: 20px;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(25px);
    background-color: rgb(130 209 203 / 5%);
    backdrop-filter: blur(25px);
    text-align: center;
}
.welfare>div {
    display:flex;
    justify-content:center;
    width:10rem;
    height: 10rem;
    background-color: #fff;
    border-radius:30px;
    margin: 60px auto 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.welfare img {
    display: block;
    width: 40px;
    margin:auto;
}
.welfare span {
    display: block;
    margin-top: 2.5rem;
    font-size: 2.1rem;
    line-height: 1.43;
    font-weight: 500;
}
.recruit-fourth div ul li p {

}
.recruit-fourth .list-on p {
    height: auto;
    visibility: visible;
}

.job-postings-list li {list-style: none;}

.recruit-fourth header p {
    font-size: 2.1rem;
    margin-bottom: 10rem;
    margin-top: 4rem;
}
details {
    padding: 3rem 0;
    margin-right: 4rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 0;
    border-right: 0;
}
details:first-child {border-top: 0;}
details summary::marker {
    content: '';
}
details summary {
    font-family: OpenSans;
    font-weight: bold;
    font-size: 3.5rem; 
    cursor: pointer;
    position: relative;
}
details summary::after {
    display: inline;
    content: '+';
    font-size: 2.8rem;
    position: absolute;
    right: 0rem;
    font-weight:initial;
}
details[open] summary::after {
    content: '-';
}
details > div {
    padding: 3rem 0;
}
details > div > span {
    font-size: 1.8rem; 
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 1.7;
}
details > div > p {
    font-size: 1.8rem;
    margin-bottom: 2.5rem;
    line-height: 1.6;
}
details > div > p:last-child {margin-bottom: 0;}

@media (max-width:1024px) {
    .recruit {
        width: 100%;
        height: auto;
    }

    .recruit section {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #000;
        color: #fff;
        word-break: keep-all;
        padding-bottom: 2rem;
    }

    .recruit>section>header {
        text-align: center;
        padding-top: 0rem;
        margin-top: 20rem;
    }

    .recruit-first>header>p {
        margin: 4rem auto 10rem;
    }

    #talent {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 432px;
        margin:0 auto;
    }
    #talent .move-btn {
        position: absolute;
        display: block;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        z-index: 2;
        bottom: 20vh;
    }

    #talent .move-btn>img {
        width: 28px;
        height: 56px;
    }
    #talent .swiper-btn-container {
        bottom: -8rem !important;
        display: inline-block;
    }

    #talent .prev-btn {
        left: -10vw;
    }

    #talent .next-btn {
        right: -10vw;
    }
    #talent .talent-box {
        scroll-snap-align: start;
        display: block;
        height: 49.6rem;
        min-width: 35rem;
        max-width: 35.2rem;
        margin: 0 19px;
    }

    .talent-box>img {
    }

    .talent-box>p {
        min-width: 220px;
        text-align: left;
        font-weight: bold;
    }

    .talent-box>span {
        display: block;
        min-width: 220px;
        margin-bottom: 13rem;
    }
    #careers {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 432px;
        margin: 0 auto;
    }

    #careers .move-btn {
        position: absolute;
        display: block;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        z-index: 2;
        bottom: 20vh;
    }

    #careers .move-btn>img {
        width: 28px;
        height: 56px;
    }

    #careers .prev-btn {
        left: -10vw;
    }

    #careers .next-btn {
        right: -10vw;
    }

    #careers .talent-box {
        scroll-snap-align: start;
        display: block;
        width: 432px;
        height: auto;
        min-height: 510px;
        margin: 0 19px;
        border-radius: 10px;
        background-color: #18191f;
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
        word-break: keep-all;
        font-size: 0.88rem;
    }
    #careers .careers-box {
        scroll-snap-align: start;
        display: block;
        scroll-snap-align: start;
        display: block;
        height: 49.6rem;
        min-width: 35rem;
        max-width: 35.2rem;
        margin: 0 19px;
        margin-bottom: 3rem;
    }

    #careers .swiper-btn-container {
        bottom: -8rem !important;
        display: inline-block;
    }

    .careers-box>img {
        width: 350px;
        min-width: 220px;
    }

    .careers-box>p {
        min-width: 220px;
    }

    .careers-box>span {
        display: block;
        min-width: 220px;
    }

    .recruit-thrid {
        background-image: url('../../images/Recruit/bg.png');
        background-size: cover;
    }

    #welfare-box {
        width:90vw;
        margin: 8rem auto;
    }

    #welfare-box .swiper-btn-container {
        display: none;
    }

    #welfare-box>.swiper-slide>.scroll-home-second-xscroll {
        flex-flow: row wrap;
        padding: 0rem 2rem;
    }

    .welfare {
        width: 35.5%;
        height: 30rem;
        border-radius: 10px;
        text-align: center;
        margin: 1.5rem;
    }

    .welfare span {
        display: block;
        margin-top: 25px;
    }

}
@media (max-width: 780px){

    .welfare {
        width: 24rem;
        height: 30rem;
        border-radius: 10px;
        text-align: center;
        margin: 1.5rem;
    }
}

@media (max-width:600px) {
    html{
        font-size: 62.5%;
    }
    .recruit {
        width: 100%;
        height: auto;
    }
    #careers .swiper-btn-container,#talent .swiper-btn-container {
        bottom: -5rem !important;
    }
    .recruit section {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: auto;
        height: auto;
        background-color: #000;
        color: #fff;
        word-break: keep-all;
    }

    .recruit>section>header {
        text-align: center;
        padding-top: 0rem;
        margin-top: 12rem;
    }

    .recruit>section>header>span {
        font-size: 3.5rem;
        width: 55%;
        display: block;
        margin: 0 auto;
    }

    .recruit-first>header>p {
        font-size: 1.6rem;
        margin: 4rem auto 6rem;
        width: 80vw;
    }

    .recruit-second>header>p {
        font-size: 1.6rem;
        margin: 4rem auto 6rem;
        width: 66vw    
    }

    #talent {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 90vw;
        margin: 0 auto;
    }
    #talent .move-btn,
    #careers .move-btn {
        display: none;
    }

    #talent .talent-box {
        scroll-snap-align: start;
        display: block;
        min-width: 76%;
        min-height: 0;
        margin: 0 19px;
        border-radius: 10px;
        word-break: keep-all;
        
    }

    .talent-box>img {
        width: 100%;
    }
    .talent-box>span {
        font-size: 1.4rem;
        width: 60%;
        line-height: 1.71;
    }

    #careers {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 90vw;
        margin: 0 auto;
    }

    #careers .talent-box {
        scroll-snap-align: start;
        display: block;
        width: 90vw;
        height: auto;
        min-height: 0;
        margin: 0 19px;
        border-radius: 10px;
        background-color: #18191f;
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
        word-break: keep-all;
        font-size: 0.88rem;
    }

    #careers .careers-box {
        scroll-snap-align: start;
        display: block;
        min-width: 76%;
        height: auto;
        min-height: 0;
        margin: 0 19px;
        border-radius: 10px;
        word-break: keep-all;
        background-color: rgba(24, 25, 31, 0.5);

    }

    .careers-box>img {
        width: 100%;
    }

    .careers-box>span {
        display: block;
        font-family: SpoqaHanSansNeo;
        text-align: left;
        line-height: 1.71;
        margin-bottom: 4rem;
        font-size: 1.4rem;
    }

    .recruit-thrid {
        background-image: url('../../images/Recruit/bg.png');
        background-size: cover;
    }

    .recruit-thrid p{font-size: 1.6rem;}

    #welfare-box {
        width: 90vw;
        min-width: unset;
        margin: 50px auto;
    }

    #welfare-box .swiper-btn-container {
        display: flex;
    }
    #welfare-box .swiper-slide {
        width: 89%;
        position: relative;
    }
    #welfare-box>.swiper-slide>.scroll-home-second-xscroll {
        flex-flow:unset;
    }

    #welfare-box > div > div.swiper-btn-container {
        bottom: -45px !important;
        transform: translateX(-43%);
    }

    .welfare {
        scroll-snap-align: start;
        min-width: 88vw;
        height: 350px;
        margin: 0 18px;
        border-radius: 10px;
        -webkit-backdrop-filter: blur(25px);
        background-color: rgb(130 209 203 / 5%);
        backdrop-filter: blur(25px);
        text-align: center;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
    }

    .welfare>div {
        display: flex;
        justify-content: center;
        width: 100px;
        height: 100px;
        background-color: #fff;
        margin: 0 auto 0;
    }

    .welfare span {
        font-size: 1.6rem;
        line-height: 1.63;
    }

    .welfare img {
        display: block;
        width: 40px;
        margin: auto;
    }

    .welfare span {
        display: block;
        margin-top: 25px;
    }
    .recruit-fourth header p {font-size: 1.6rem;}
    details summary {
        font-size: 2.5rem;
    }
    details > div > span {font-size: 1.6rem;}
    details > div > p {font-size: 1.4rem; line-height: 1.63;}

}
